import styled from 'styled-components';

export const NewsContainer = styled.div`
  padding: 1rem;
`;

export const NewsItem = styled.div`
  border-top: 1px solid rgba(64, 64, 64, 0.2);
  padding: 1rem 1rem 1rem 5rem;
`;
