import React from 'react';
import { NewsContainer, NewsItem } from './News.styles';
import parse from 'html-react-parser';
import { news } from './data';

const News = () => {
  return (
    <NewsContainer>
      {news.map((item) => (
        <NewsItem key={item.id}>
          {item.date} | {item.title}
          <p>{parse(item.text)}</p>
        </NewsItem>
      ))}
    </NewsContainer>
  );
};

export default News;
