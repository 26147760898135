import React from 'react';
import Landing from 'pages/Landing';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Landing />
    </div>
  );
}

export default App;
