import React from 'react';
import { Filter, Wrapper } from './styles';
import Content from 'components/Content';

const Landing = () => {
  return (
    <Wrapper>
      <Filter />
      <Content />
    </Wrapper>
  );
};

export default Landing;
