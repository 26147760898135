import styled from 'styled-components';
import icon64 from 'assets/icon64.png';

export const Grid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  grid-column: 1;
  grid-row: 1;
`;

export const Icon = styled.img.attrs({ src: icon64 })`
  justify-self: center;
  padding-top: 1rem;
`;

export const Title = styled.h1`
  text-align: center;
  margin: 0;
`;

export const Footer = styled.div`
  text-align: center;
  padding: 1rem;
`;
