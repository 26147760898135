import styled from 'styled-components';

export const SocialWrapper = styled.div`
  display: grid;
  justify-content: center;
`;

export const Instagram = styled.img.attrs((props) => ({ src: props.src }))`
  cursor: pointer;
  height: 64px;
`;
