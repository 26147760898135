import React from 'react';
import InstagramLogo from 'assets/social/instagramLogo.png';
import { SocialWrapper, Instagram } from './Social.styles';

const Social = () => {
  return (
    <SocialWrapper>
      <a
        href='https://www.instagram.com/iser_music/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Instagram src={InstagramLogo} alt='Instagram' />
      </a>
    </SocialWrapper>
  );
};

export default Social;
