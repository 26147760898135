import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
`;

export const Filter = styled.div`
  grid-row: 1;
  grid-column: 1;  
  z-index: -1;
  background-color: white;
`;
