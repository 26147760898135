import React from 'react';
import Album from 'components/Album';
import { AlbumGrid } from './Albums.styles';

const Albums = ({ data }) => {
  return (
    <AlbumGrid>
      {data.map((album) => (
        <Album key={album.id} {...album} />
      ))}
    </AlbumGrid>
  );
};

export default React.memo(Albums);
