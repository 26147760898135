import { v4 as uuid } from 'uuid';

export const news = [
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '30-05-2023',
    text: 'The album <strong>Introspection</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '15-04-2022',
    text: 'The album <strong>Subtle Presence</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '09-07-2021',
    text: 'The album <strong>Cycles</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '23-01-2021',
    text: 'The album <strong>First Contact</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '18-11-2020',
    text: 'The album <strong>Timeless</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '01-10-2020',
    text: 'The album <strong>Secret Place</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '05-08-2020',
    text: 'The album <strong>Evening Thoughts</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '21-06-2020',
    text: 'The album <strong>Atmos</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '07-05-2020',
    text: 'The album <strong>Night</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '11-04-2020',
    text: 'The album <strong>Forest</strong> Wind has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '01-01-2020',
    text: 'The album <strong>Calm</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '05-11-2019',
    text: 'The album <strong>Autumn</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '02-10-2019',
    text: 'The album <strong>Space</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'New Release!!!',
    date: '16-09-2019',
    text: 'The album <strong>Light & Shadow</strong> has been released.',
  },
  {
    id: uuid(),
    title: 'First Release!!!',
    date: '02-09-2019',
    text: 'The first Iser album <strong>DREAMS</strong> has been released.',
  },
];
