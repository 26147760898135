import React from 'react';
import { Grid, Icon, Title, Footer } from './Content.styles';
import Tabs from 'components/Tabs';
import Social from 'components/Social';
import ReactJkMusicPlayer from 'react-jinke-music-player';
import 'react-jinke-music-player/assets/index.css';
import config from 'config';

const playerOptions = {
  audioLists: config.playList,
  showDownload: false,
  showThemeSwitch: false,
  preload: false,
  defaultPosition: {
    left: 10,
    top: 10,
  },
  showDestroy: false,
  remove: false,
  drag: false,
  autoPlay: false,
};

const Content = () => {
  return (
    <Grid>
      <Icon />
      <Title>{config.title}</Title>
      <Social />
      <Tabs />
      <ReactJkMusicPlayer {...playerOptions} />
      <Footer>{config.footer}</Footer>
    </Grid>
  );
};

export default Content;
