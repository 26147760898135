import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  align-self: start;
`;

export const ComingSoon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1 / span 3;
  grid-column: 1;
  background-color: rgba(64, 64, 64, 0.5);
  z-index: 100;
  color: white;
  font-size: 1.5rem;
`;

export const Title = styled.h2`
  text-align: center;
`;

export const Artwork = styled.img.attrs((props) => ({ src: props.src }))`
  grid-row: 2;
  grid-column: 1;
  justify-self: center;
  box-shadow: 0px 4px 16px 4px rgba(0, 0, 0, 0.75);
  margin-bottom: 1rem;
`;

export const Stores = styled.div`
  grid-row: 3;
  grid-column: 1;
  display: grid;
  grid-template-columns: repeat(4, 32px);
  justify-self: center;
  justify-items: center;
  gap: 1rem;
`;

export const Store = styled.a``;

export const StoreIcon = styled.img.attrs((props) => ({ src: props.src }))`
  cursor: pointer;
  height: 32px;
`;

export const ExternalLink = styled.a`
  text-align: center;
`;
