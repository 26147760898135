import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const TabsStyled = styled.div`
  display: block;
`;

export const Navigation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
`;

export const Tab = styled(NavLink)`
  font-size: 2rem;
  cursor: pointer;
  padding: 0 2rem;
  text-decoration: none;
  color: black;
  justify-self: ${(props) => props.orientation};

  &.${(props) => props.activeClassName} {
    border-bottom: 4px solid green;
  }
`;

export const TabActive = styled.div`
  border-bottom: 1px solid green;
`;
