import React from 'react';
import {
  Container,
  Artwork,
  Stores,
  Store,
  StoreIcon,
  ComingSoon,
  ExternalLink,
} from './Album.styles';
import { comingSoon } from './constants';

const Album = ({ id, artwork, stores, isAvailable = true, homePage }) => {
  return (
    <Container>
      {!isAvailable && <ComingSoon>{comingSoon}</ComingSoon>}

      {homePage ? (
        <ExternalLink href={homePage} target='_blank' rel='noopener noreferrer'>
          <Artwork src={require(`assets/artwork/${artwork}`)} />
        </ExternalLink>
      ) : (
        <Artwork src={require(`assets/artwork/${artwork}`)} />
      )}

      <Stores>
        {stores.map((store) => {
          return (
            <Store key={`${store.name}-${id}`} href={store.url} target='_blank' rel='noopener'>
              <StoreIcon src={require(`assets/stores/${store.icon}`)} alt='' />
            </Store>
          );
        })}
      </Stores>
    </Container>
  );
};

export default Album;
