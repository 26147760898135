const config = {
  title: 'Iser | Music',
  albums: [
    {
      id: 1,
      title: 'Dreams',
      artwork: 'Dreams.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/3VQYQZglMe9Yf73X6zrPfv',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/dreams/1478533382',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/gp/product/B07XBC3JKM',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/109528062',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 2,
      title: 'LightAndShadow',
      artwork: 'LightAndShadow.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/4fFdikpimjOpGOBrGdlJbK',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/light-shadow/1480152380',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'http://www.amazon.com/gp/product/B07XXB53N9',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/111270352',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 3,
      title: 'Space',
      artwork: 'Space.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/43DMxx43zN8eoLZUEkSBAI',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/space/1482051762',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'http://www.amazon.com/gp/product/B07YMLK924',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/113320402',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 4,
      title: 'Autumn',
      artwork: 'Autumn.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/4XVDGqWIdyR1vYcd9stR3O',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/autumn/1486195574',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/gp/product/B07ZZHZZ5F',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/117609302',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 5,
      title: 'Calm',
      artwork: 'Calm.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/6jHK7burlXoPQeJd9lD2N5',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/calm/1493289417',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/Calm/dp/B083GNTPH4',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/124991752',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 6,
      title: 'Forest Wind',
      artwork: 'ForestWind.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/4df9RxR5GNM1GJoMKoQbSz',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/forest-wind/1507293524',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/Forest-Wind/dp/B086XFW3LK',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/140885652',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 7,
      title: 'Night',
      artwork: 'Night.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/3bBJ69cc9wZqwz2NU5av9X?si=77wwI70VQaKhW0t78iVLig',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/night/1511795955',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/Night-Iser/dp/B0881BK14M',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/146027232',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 8,
      title: 'Atmos',
      artwork: 'Atmos.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/3wSlOm1SBeLW0q2lFmhZwM',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/atmos/1517972885',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/Atmos-Iser/dp/B08B2TLYYP',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/154056852',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 9,
      title: 'EveningThoughts',
      artwork: 'EveningThoughts.png',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/6MDJTIjhTQZlXvDq7X2n4l',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/evening-thoughts/1526226663',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/Evening-Thougths/dp/B08F7HXSPP',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/164889752',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 10,
      title: 'SecretPlace',
      artwork: 'SecretPlace.png',
      isAvailable: true,
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/0fgzYgttdBNqh7gjWKQQTD?si=_-618mZLTeGCyRXXF4Cezg',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/secret-place/1533088293',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/gp/product/B08JVLRPFX',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/175270692',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 11,
      title: 'Timeless',
      artwork: 'Timeless.png',
      isAvailable: true,
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/08J2laa3HQZWKyz6yhiZWU?si=t-L0N6nZRpqnpGLJ5rSBUA',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/timeless/1540849571',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://www.amazon.com/gp/product/B08NT9WXVL',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/187139172',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 12,
      title: 'First Contact',
      artwork: 'FirstContact.png',
      isAvailable: true,
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/6kf6qn73qPrgVypOEnzW2X?si=ipI28125SS-1rAgryiFyqQ',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/first-contact/1550459980',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://music.amazon.com/albums/B08TV5DDKH',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/202304372',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 13,
      title: 'Cycles',
      artwork: 'Cycles.png',
      isAvailable: true,
      homePage: 'https://cycles.iser-music.com/',
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/6nkLel4W3I5Vx5gkdUwpfl',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/cycles/1575750163',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://music.amazon.com/albums/B098XWZCXW',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/243016702',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 14,
      title: 'Subtle Presence',
      artwork: 'SubtlePresence.png',
      isAvailable: true,
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/07PAjZhdoYgM9ILuwedYyG',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/subtle-presence/1620028386',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://music.amazon.com/albums/B09YB8V9GL',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/312097447',
          icon: 'deezer64.png',
        },
      ],
    },
    {
      id: 15,
      title: 'Instrospection',
      artwork: 'Instrospection.png',
      isAvailable: true,
      stores: [
        {
          name: 'Spotify',
          url: 'https://open.spotify.com/album/1B9YI02BhOGIFTijx6VYKB',
          icon: 'spotify64.png',
        },
        {
          name: 'Apple',
          url: 'https://music.apple.com/us/album/introspection/1689971886',
          icon: 'apple64.png',
        },
        {
          name: 'Amazon',
          url: 'https://music.amazon.com/albums/B0C6JK2L9Z',
          icon: 'amazon64.png',
        },
        {
          name: 'Deezer',
          url: 'https://www.deezer.com/en/album/446897055',
          icon: 'deezer64.png',
        },
      ],
    },
  ],
  about: 'Iser is a multi-instrumental musical project created by OJ Bot.',
  footer: '© Iser | AlterSounds',
  playList: [
    {
      name: 'Forest Wind',
      singer: 'Iser',
      cover: require('assets/artwork/ForestWind.png'),
      musicSrc: require('assets/mp3/Forest Wind.mp3'),
    },
    {
      name: 'Dreams',
      singer: 'Iser',
      cover: require('assets/artwork/Dreams.png'),
      musicSrc: require('assets/mp3/Dreams.mp3'),
    },
    {
      name: 'Trees',
      singer: 'Iser',
      cover: require('assets/artwork/ForestWind.png'),
      musicSrc: require('assets/mp3/Trees.mp3'),
    },
  ],
};

config.albums = config.albums.reverse();

export default config;
