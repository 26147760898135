import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { TabsStyled, Navigation, Tab } from './Tabs.styles';
import Albums from 'components/Albums';
import News from 'components/News';
import config from 'config';

const tabsList = [
  {
    id: 1,
    title: 'Albums',
    path: '/',
    orientation: 'right',
  },
  {
    id: 2,
    title: 'News',
    path: '/news',
    orientation: 'left',
  },
];

const Tabs = () => {
  return (
    <Router>
      <TabsStyled>
        <Navigation>
          {tabsList.map((tab) => (
            <Tab
              exact
              key={tab.id}
              to={tab.path}
              activeClassName='selected'
              {...tab}
            >
              {tab.title}
            </Tab>
          ))}
        </Navigation>

        <Switch>
          <Route exact path='/'>
            <Albums data={config.albums} />
          </Route>

          <Route path='/news'>
            <News />
          </Route>
        </Switch>
      </TabsStyled>
    </Router>
  );
};

export default Tabs;
